html,
body {
  height: 100%;
  background: #ffe600;
  display: flex;
  justify-content: center;
  align-items: center;

  [role='main'] {
    display: block;
  }
}

/* TODO: to improve in lib for big title */
.catalog-landing__logo {
  flex-wrap: wrap;
}
