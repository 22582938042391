/* eslint-disable no-alert */
$white: #fff;
$black: #000;
$gray: #f0f0f0;
$gray-light: #f2f2f2;
$gray-dark: #323232;
$gray-less-dark: #3f3f3f;
$gray-3a-dark: #3a3a3a;
$gray-200: #333;
$gray-323: #323c46;
$gray-666: #666;
$gray-less-dark: #3f3f3f;
$gray-3a-dark: #3a3a3a;
$gray-4a-dark: #4a4a4a;
$gray-777: #777;
$gray-999: #999;
$gray-C4C4: #c4c4c4;
$gray-600: #ccc;
$gray-400: #dedede;
$gray-300: #ededed;
$gray-350: #e6e6e6;
$gray-900: #d2d2d2;
$gray-690: #d7d7d7;
$gray-1010: #ddd;
$gray-700: #eee;
$gray-750: #ddd;
$gray-500: #f5f5f5;
$gray-e5: #e5e5e5;
$green: #3cb64d;
$green-dark: #00a650;
$blue-link: #0c3ab7;
$andes-blue-link: #3483fa;
$blue-button-1: #3f53a2;
$blue-button-2: #2a337b;
$blue-button-3: #2c3176;
$light-blue-1: #e4f2f9;
$light-blue-2: #3a87ad;
$red-cancelled: #d0011b;
$red-error-andes: #f23d4f;
$font-size-xxxxxsmall: 9px;
$yellow-header: #fedc13;
$yellow-certification: #ffe600;
$yellow-shipment: #f57819;
$orange-warning: #f73;
$blue-hover: #c5d9f6;
$yellow-box-shadow: #e6cf00;
$purple: #9013fe;
$light-yellow-header: #fff159;
$red-code-block-delimiter: #e4185c;
$green-code-block-key: #aadb1e;
$black-01-opacity: rgb(0 0 0 / 10%);
$black-02-opacity: rgb(0 0 0 / 20%);
$black-03-opacity: rgb(0 0 0 / 30%);
$black-06-opacity: rgb(0 0 0 / 60%);
$black-08-opacity: rgb(0 0 0 / 80%);
$black-09-opacity: rgb(0 0 0 / 90%);
$black-005-opacity: rgb(0 0 0 / 5%);
$black-007-opacity: rgb(0 0 0 / 7%);
$black-012-opacity: rgb(0 0 0 / 12%);
$black-015-opacity: rgb(0 0 0 / 15%);
$black-025-opacity: rgb(0 0 0 / 25%);
$black-045-opacity: rgb(0 0 0 / 45%);

// Custom
$gray-680: #d8d8d8;
$white-055-opacity: rgb(255 255 255 / 55%);
$andes-focus-ring: rgb(0 103 244 / 24.7%);
$andes-background-blue-silent: rgb(65 137 230 / 10%);
$andes-button-loud-hover: #2968c8;
$andes-success-icon: #39b54a;
$andes-error-secondary: #d12440;
$platinum-color: #468571;
$platinum-background: rgb(161 206 192 / 40%);
$gold-color: #b2a100;
$gold-background: rgb(255 230 0 / 10%);
$silver-color: #999;
$silver-background: rgb(153 153 153 / 10%);
$default-font-family: 'Proxima Nova', -apple-system, 'Helvetica Neue', helvetica,
  roboto, arial, sans-serif, sans-serif;
$font-family-primary-bold: 'ProximaNova-Bold';
$font-family-primary-light: 'ProximaNova-light';
$font-family-primary-regular: 'ProximaNova-Regular';
$font-family-primary-semibold: 'ProximaNova-Semibold';
