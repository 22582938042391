.nav-logo {
  position: static;
  height: 42px;
  width: 80px;
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/268205826549-Mercado-Libre--3-.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-indent: 80px;
  overflow: visible;
  text-decoration: none;

  > span {
    font-size: 18px;
    font-weight: 700;
    line-height: 42px;
    color: #2d3277;
    white-space: nowrap;
    @media (width <= 580px) {
      font-size: 13px;
      display: none;
    }
  }
}

.header-nav {
  position: relative;
  background-color: $white;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
  height: 56px;
  width: 100%;
  z-index: 101;
}

.header-nav__wrapper {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 1200px;
}

.nav-options {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  z-index: 2;

  @media (width <= 768px) {
    margin-right: 16px;
  }
}

.nav-option {
  margin-left: 18px;
  font-size: 16px;
  color: $black-08-opacity;

  @media (width <= 768px) {
    margin-left: 10px;
  }
}

.nav-option > a,
.nav-option > a:link {
  font-size: 16px;
  color: $black-08-opacity;
  text-decoration: none;

  &:hover,
  &:visited {
    color: $black;
  }

  @media (width <= 768px) {
    font-size: 12px;
  }
}

.nav-option__create-account {
  border-left: 1px solid $black-045-opacity;
  padding: 6px 0;
  padding-left: 14px;
}

.nav-option__user {
  position: relative;
}

.nav-option__user-toggle-menu {
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: inherit;
  color: inherit;

  &:focus,
  &:hover {
    color: $black;
  }

  img {
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }

  svg {
    color: $black-045-opacity;
    margin-top: 2px;
  }
}

.nav-option__user-text {
  margin: 0 4px 0 8px;
}

.menu-user {
  display: none;
  box-sizing: border-box;
  position: absolute;
  background-color: $white;
  box-shadow: 0 6px 16px 0 $black-01-opacity;
  border-radius: 6px;
  width: 300px;
  right: -84px;
  top: calc(100% + 18px);
  list-style: none;
  padding: 0;

  &::before {
    content: '';
    position: absolute;
    right: 82px;
    top: -9px;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    background-color: $white;
  }

  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 12px;
  }

  @media (width <= 768px) {
    right: -1px;
  }
}

.menu-user:focus,
.menu-user:active,
.nav-option__user-toggle-menu:focus + .menu-user,
.nav-option__user-toggle-menu:active + .menu-user {
  display: block;
}

.menu-user:hover,
.nav-option__user-toggle-menu:hover + .menu-user {
  @media (width <= 768px) {
    display: block;
  }
}

.menu-user__option {
  font-size: 14px;
  line-height: 1.29;
  color: $black-08-opacity;
  display: flex;
  align-items: center;

  & + .menu-user__option {
    border-top: 1px solid $black-01-opacity;
  }
}

.menu-user__option a,
.menu-user__option a:link {
  box-sizing: border-box;
  padding: 16px 18px;
  font-size: 14px;
  line-height: 1.29;
  color: $black-08-opacity;
  text-decoration: none;
  width: 100%;

  &:hover {
    background-color: $gray;
  }
}

.menu-user__greetings {
  padding: 22px 18px;
  color: $black-045-opacity;
}
